<template>
  <v-container fluid>
    <div>
      <v-card>
        <v-card-text>
          <v-row>
            <v-col cols="12" sm="4" md="4">
              <v-autocomplete
                :items="wells"
                item-value="id"
                item-text="wellname"
                label="Well"
                v-model="well_selected"
                @change="select_well"
                clearable
                return-object
                dense
              >
              </v-autocomplete>
            </v-col>
            <v-col cols="12" sm="4" md="4">
              <v-autocomplete
                :items="formations"
                v-model="formations_selected"
                item-value="id"
                item-text="formationname"
                label="Formation"
                @change="add_wells"
                multiple
                dense
                return-object
                clearable
              >
              </v-autocomplete>
            </v-col>
            <v-col cols="12" sm="2" md="2">
              <v-btn
                color="blue darken-1"
                :disabled="disable_select"
                @click.stop="show_map"
                class="ma-1"
              >
                Select
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </div>
    <div id="container">
      <svg
        id="svg"
        xmlns="http://www.w3.org/2000/svg"
        svg
        x="-20px"
        y="-50px"
        :width="wdth"
        height="700px"
        style="overflow-x: auto; overflow-y: auto"
        :viewBox="box"
      ></svg>
    </div>
    <selectWell
      ref="Ref1"
      :wells="wells"
      :well_selected="well_selected"
      @close="close_map"
      @select="get_selectedwells"
      :showForm="showForm"
      :key="kfor"
    ></selectWell>
    <waiter :showWait="showWait"> </waiter>
  </v-container>
</template>

<script>
import WELLS from "../graphql/Formation_Analyse.gql";
export default {
  components: {
    selectWell: () => import("../components/Well_select_map.vue"),
    waiter: () => import("../components/Widgets/Waiter.vue"),
  },

  data: () => ({
    showWait: false,
    box: "-20 -50 2000 700",
    wdth: "2000px",
    showForm: false,
    skey: 100,
    wells: [],
    well_selected: {},
    wells_selected: [],
    formations: [],
    formations_selected: [],
    ctl: false,
    diameter: 23,
    tilt: 8,
    disable_select: true,
    kfor: -1,
  }),

  created() {
    this.test();
  },
  async mounted() {
    this.showWait = true;
    let r = await this.$apollo.query({
      query: WELLS,
    });
    if (r) {
      this.wells = r.data.wells_list;
    }
    // this.$nextTick(() => {});
    this.showWait = false;
  },
  methods: {
    select_well(item) {
      if (item) {
        this.formations = item.wellsformations;
        this.well_selected = item;
        this.disable_select = false;
        this.wells_selected = [];
        //this.wells_selected.push(item);
        this.add_wells();
      }
    },
    add_wells() {
      let left = 0;
      var main = document.getElementById("svg");
      var child = document.getElementById("svgg");
      if (child) main.removeChild(child);
      var svgn = "http://www.w3.org/2000/svg";
      var g = document.createElementNS(svgn, "g");
      g.setAttributeNS(null, "id", "svgg");
      let u = 0.09;
      if (this.wells_selected.length > 0) {
        //  this.wells_selected.forEach((t) =>
        for (
          let i = 0;
          i <
          this.wells_selected.sort((a, b) => a.longitude - b.longitude).length;
          i++
        ) {
          let t = this.wells_selected[i];
          let k = t.wellstubulars.filter((x) => x.tubular_id == 1);
          if (k.length > 0)
            if (k[0].depthto) {
              if (this.formations_selected.length > 0) {
                this.formations_selected
                  .sort((a, b) => a.formation_order - b.formation_order)
                  .forEach((f) => {
                    let k = t.wellsformations.findIndex(
                      (wf) => wf.formation_id == f.formation_id
                    );
                    if (k >= 0) {
                      let fx = t.wellsformations[k];
                      // ---------------------t1
                      let fx2 = fx;
                      if (i < this.wells_selected.length)
                        if (this.wells_selected[i + 1]) {
                          let t2 = this.wells_selected[i + 1];

                          let k2 = t2.wellsformations.findIndex(
                            (x2) => x2.formation_id == f.formation_id
                          );
                          if (k2 >= 0) {
                            fx2 = t2.wellsformations[k2];
                          }

                          g.appendChild(
                            this.formation_svg(
                              left + 33,
                              fx.depthfrom * u,
                              left + 200,
                              fx2.depthfrom * u,
                              fx.depthto * u,
                              fx2.depthto * u,
                              fx.color
                            )
                          );
                        }
                      //startX, startY, endX, endY, heightX, heightY, color
                      //--------------------------
                    }
                  });
              }
              g.appendChild(
                this.cylinder_svg(t.id, t.wellname, left, k[0].depthto * u)
              );
              //-------------------------
              left = left + 200;
            }
        }
        // });
      }
      main.appendChild(g);
    },
    cylinder_svg(id, str, left, height) {
      var svgn = "http://www.w3.org/2000/svg";
      var g = document.createElementNS(svgn, "g");
      var path = document.createElementNS(svgn, "path");
      let th = height * Math.cos((0.25 * Math.PI) / 2);
      let d =
        "M " +
        left +
        ",4.64 a 16,4.64 0,0,0 32 0 a 16,4.64 0,0,0 -32 0 l 0," +
        th +
        " a 16,4.64 0,0,0 32 0 l 0,-" +
        th;
      if (id == this.well_selected.id)
        path.setAttributeNS(null, "fill", "blue");
      else path.setAttributeNS(null, "fill", "gray");
      path.setAttributeNS(null, "stroke", "black");
      path.setAttributeNS(null, "d", d);
      g.appendChild(path);
      g.appendChild(this.text_svg(str, left));

      return g;
    },
    text_svg(str, left) {
      var svgn = "http://www.w3.org/2000/svg";
      var text = document.createElementNS(svgn, "text");
      text.setAttributeNS(null, "x", left + 15);
      text.setAttributeNS(null, "y", -20);
      text.setAttributeNS(null, "fill", "white");
      text.setAttributeNS(null, "font-family", "AvenirNext-Bold");
      text.setAttributeNS(null, "font-size", "18");
      text.setAttributeNS(null, "font-weight", "bold");
      text.setAttributeNS(null, "text-anchor", "middle");
      text.textContent = str;
      return text;
    },

    formation_svg(Ax, Ay, Bx, By, Adepthto, Bdepthto, color) {
      var svgn = "http://www.w3.org/2000/svg";
      var path = document.createElementNS(svgn, "path");
      var g = document.createElementNS(svgn, "g");
      g.setAttributeNS(null, "id", "svgg");
      let d = "M   " + Ax + "  " + Ay;
      d += " L  " + Bx + "  " + By;
      d += " L  " + Bx + "  " + Bdepthto;
      d += " L  " + Ax + "  " + Adepthto;

      " Z" + Adepthto;
      path.setAttributeNS(null, "d", d);

      path.setAttributeNS(null, "fill", color);
      g.appendChild(path);
      return g;
    },

    show_map() {
      //
      this.showForm = true;
      this.kfor++;
    },
    close_map() {
      this.init_map = false;
      this.showForm = false;
    },
    get_selectedwells(item) {
      if (item)
        if (item.length > 0) {
          this.wells_selected = item;
          this.box =
            "-20 -50 " + (this.wells_selected.length * 200).toString() + " 700";
          this.wdth = this.wells_selected.length * 200 + "px";
          this.add_wells();
          this.showForm = false;
        }
    },
    //---------------------------TEST D3
    test() {},
  },
  ///-----------------------------------
};
</script>

<style>
#container {
  width: 2000;
  height: 700;
  overflow: auto;
}
#svg {
  margin: 50px;
}
</style>
